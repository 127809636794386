import React from 'react';

function Logo(props) {
    return (
        <img
            width={75}
            alt="Logo"
            src="/static/logo.svg"
            {...props}
        />
    );
}

export default Logo;
