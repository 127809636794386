import Token from "../../interfaces/Token";
import { SET_TOKEN } from "../actionTypes";

const initialState: Token = {
	jwt: "",
	refresh_token: "",
};

export default function(state = initialState, action: any) {
	switch (action.type) {
		case SET_TOKEN: {
			return {
				...action.payload,
			};
		}
		default:
			return state;
	}
}
