import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Button,
    Box,
    Container,
    Grid,
    Typography,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        paddingTop: 128,
        paddingBottom: 128
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
}));

function Features({className, ...rest}) {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Container maxWidth="lg">
                <Typography
                    component="p"
                    variant="overline"
                    color="secondary"
                    align="center"
                >
                    Explore Aureum Digital Dashboard
                </Typography>
                <Typography
                    variant="h1"
                    align="center"
                    color="textPrimary"
                >
                    Not just a pretty face
                </Typography>
                <Box mt={8}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            <Box display="flex">
                                <Avatar className={classes.avatar}>
                                    01
                                </Avatar>
                                <Box ml={2}>
                                    <Typography
                                        variant="h4"
                                        gutterBottom
                                        color="textPrimary"
                                    >
                                        Intuitive
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                    >
                                        No more fighting against skydiving apps, we wanna help you to enjoy
                                        filling your logbook with the minimum effort.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            <Box display="flex">
                                <Avatar className={classes.avatar}>
                                    02
                                </Avatar>
                                <Box ml={2}>
                                    <Typography
                                        variant="h4"
                                        gutterBottom
                                        color="textPrimary"
                                    >
                                        Safe
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                    >
                                        Your jumps will be secured on the cloud so you won't have to worry about
                                        losing your logbook any more. Although your data only own by you, and only
                                        you control who can access it.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            <Box display="flex">
                                <Avatar className={classes.avatar}>
                                    03
                                </Avatar>
                                <Box ml={2}>
                                    <Typography
                                        variant="h4"
                                        gutterBottom
                                        color="textPrimary"
                                    >
                                        Smart
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        gutterBottom
                                    >
                                        Using cutting edge AI technologies we will match all the available
                                        sources to provide the maximum value from your jumps.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

               {/* <Box mt={4}>
                    <Grid className={classes.cta}
                          container
                          justify="center"
                          alignItems="center">
                        <Button
                            variant="outlined"
                            component="a"
                            href="/login">
                            Preview Demo
                        </Button>
                    </Grid>
                </Box>*/}

            </Container>
        </div>
    );
}

Features.propTypes = {
    className: PropTypes.string
};

export default Features;
