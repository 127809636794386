import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    makeStyles,
    SvgIcon
} from '@material-ui/core';
import {Menu as MenuIcon} from 'react-feather';
import Logo from 'src/components/Logo';
import {THEMES} from 'src/constants';
import Account from './Account';
import Contacts from './Contacts';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...theme.name === THEMES.LIGHT ? {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.main
        } : {},
        ...theme.name === THEMES.ONE_DARK ? {
            backgroundColor: theme.palette.background.default
        } : {}
    },
    logo: {
        marginRight: theme.spacing(2),
        width: "75px",
        ...theme.palette.type === 'light' ? {
            filter: 'invert(19%) sepia(99%) saturate(4316%) hue-rotate(328deg) brightness(88%) contrast(91%)'
        } : {
            filter: 'invert(100%)'
        },
    },
    toolbar: {
        minHeight: 64
    }
}));

function TopBar({
                    className,
                    onMobileNavOpen,
                    ...rest
                }) {
    const classes = useStyles();

    return (
        <AppBar
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Toolbar className={classes.toolbar}>
                <Hidden lgUp>
                    <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        onClick={onMobileNavOpen}
                    >
                        <SvgIcon fontSize="small">
                            <MenuIcon/>
                        </SvgIcon>
                    </IconButton>
                </Hidden>
                <Hidden mdDown>
                    <RouterLink to="/">
                        <Logo className={classes.logo}/>
                    </RouterLink>
                </Hidden>
                <Box
                    ml={2}
                    flexGrow={1}
                />
                <Search/>
                <Contacts/>
                <Notifications/>
                <Settings/>
                <Box ml={2}>
                    <Account/>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default TopBar;
