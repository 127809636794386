import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from 'redux-saga'
import rootReducer from "./reducers";
/*import ideaSaga from "./sagas/ideas";
import userSaga from "./sagas/user";*/

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// mount it on the Store
export default createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

// then run the saga
/*sagaMiddleware.run(userSaga);
sagaMiddleware.run(ideaSaga);*/
